<template>
  <div>
    <v-container>
      <Showallnews />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Showallnews: () => import("@/components/showallnews"),
  },
};
</script>